import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Select the root container
const container = document.getElementById('root');

const GoogleTagManager = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
      j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j,f);
      })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GOOGLE_TAG_ADS}');
    `;
    document.head.appendChild(script);
  }, []);
  return null;
};

// Check if a root already exists
if (!container._reactRootContainer) {
  // Create a new root if it does not exist
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <GoogleTagManager />
      <App />
    </React.StrictMode>
  );
} else {
  // If a root already exists, just render the app
  container._reactRootContainer.render(
    <>
      <GoogleTagManager />
      <App />
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
