import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/others/Header';
import Footer from './components/others/Footer';
import WhatsAppButton from './components/others/WhatsAppButton';
import ScrollToTop from './components/others/ScrollToTop';
import { HelmetProvider, Helmet } from 'react-helmet-async'; // Import HelmetProvider for SEO
import './i18n';
import { createRoot } from 'react-dom/client';

// Lazy Load Pages
const Homepage = React.lazy(() => import('./components/home/Homepage'));
const LocationPage = React.lazy(() => import('./components/location/LocationPage'));
const TeamPage = React.lazy(() => import('./components/team/TeamPage'));
const ContactPage = React.lazy(() => import('./components/contact/ContactPage'));
const ReviewsPage = React.lazy(() => import('./components/reviews/ReviewsPage')); // New Reviews Page

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
        <Helmet>
          <title>Cluj Tattoo Club - Premier Tattoo Studio</title>
          <meta name="description" content="Cluj Tattoo Club - Premier tattoo studio in Cluj-Napoca, offering high-quality and unique tattoo designs." />
          <meta property="og:title" content="Cluj Tattoo Club - Premier Tattoo Studio" />
          <meta property="og:description" content="Cluj Tattoo Club - Premier tattoo studio in Cluj-Napoca, offering high-quality and unique tattoo designs." />
          <meta property="og:image" content="%PUBLIC_URL%/background-tattoo_salon.png" />
          <meta property="og:url" content="https://clujtattooclub.com" />
        </Helmet>

          <Header />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/location" element={<LocationPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/reviews" element={<ReviewsPage />} /> {/* Google Reviews Page */}
            </Routes>
          </Suspense>
          <Footer />
          <ScrollToTop />
          <WhatsAppButton />
        </div>
      </Router>
    </HelmetProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export default App;
