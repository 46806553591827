import React, { useState, useEffect } from 'react';
import './css/Header.css';
import './css/Banner.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Marquee from "react-fast-marquee";

function Banner() {
  const { i18n } = useTranslation();
  
  return (
    <div className="tattoo-banner">
      <Marquee>
        <strong>{i18n.t('tattoo_artist_wanted')}</strong> {i18n.t('send_to')} <a href="mailto:clujtattooclub@yahoo.com"> clujtattooclub@yahoo.com</a>.  
        {i18n.t('social_req')}
      </Marquee>
    </div>
  );
}

function Header() {
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const changeLanguage = () => {
    const newLanguage = i18n.language === 'ro' ? 'en' : 'ro';
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <>
      <Banner />
      <header className={`hdr-header ${scroll ? 'hdr-header-scroll' : ''}`}>
        <img src="https://res.cloudinary.com/ddzmyir4a/image/upload/v1738462944/logo_maozve.png" alt="Logo" className="hdr-logo-header" />
        <div className="hdr-navigation">
          <button className="hdr-language-button" onClick={changeLanguage}>
            <img
              src={i18n.language === 'ro' ? '/images/flag_ro.png' : '/images/flag_uk.png'}
              alt="Language Flag"
              className="hdr-language-flag"
            />
          </button>
          <nav className="hdr-nav">
            <a href="/" className="hdr-nav-link">{i18n.t('home')}</a>
            <a href="/location" className="hdr-nav-link">{i18n.t('ourLocation')}</a>
            <a href="/team" className="hdr-nav-link">{i18n.t('team')}</a>
            <a href="/reviews" className="hdr-nav-link">Reviews</a>
            <a href="/contact" className="hdr-nav-link">{i18n.t('contactHeader')}</a>
          </nav>
          <FaBars className="hdr-burger-icon" onClick={toggleMenu} />
        </div>

        <div className={`hdr-curtain-menu ${menuOpen ? 'active' : ''}`}>
          <button className="hdr-close-icon" onClick={toggleMenu}><FaTimes /></button>
          <img src="https://res.cloudinary.com/ddzmyir4a/image/upload/v1738462944/logo_maozve.png" alt="Logo" className="hdr-curtain-logo" />
          <nav className="hdr-curtain-nav">
            <a href="/" className="hdr-curtain-nav-link" onClick={toggleMenu}>
              {i18n.t('home')}
            </a>
            <a href="/location" className="hdr-curtain-nav-link" onClick={toggleMenu}>
              {i18n.t('ourLocation')}
            </a>
            <a href="/team" className="hdr-curtain-nav-link" onClick={toggleMenu}>
              {i18n.t('team')}
            </a>
            <a href="/reviews" className="hdr-curtain-nav-link" onClick={toggleMenu}>
              Reviews
            </a>
            <a href="/contact" className="hdr-curtain-nav-link" onClick={toggleMenu}>
              {i18n.t('contactHeader')}
            </a>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
